:root {
    --highlightsBackgroundColor: '----';
    --highlightsHeadingTextColor: '----';
    --highlightsSubtitleTextColor: '----';
    --highlightsBodyTextColor: '----';
}

.Heading {
    position: relative;
    top: -8rem;
    display: block;
    margin-bottom: -8rem;
    margin-left: 15%;
    text-align: left;
    color: var(--highlightsHeadingTextColor, #000000);
}

.Body {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
}

.NewsArticleCard {
    min-width: 50px;
    width: 15rem;
    margin-right: 2rem;
    margin-top: 2rem;
    background-color: var(--highlightsBackgroundColor, #ffffff) !important;
}

.NewsArticleTitle {
    color: var(--highlightsBodyTextColor, #000000);
}

.NewsArticleSubtitle {
    color: var(--highlightsSubtitleTextColor, #000000);
}

.NewsArticleBody {
    color: var(--highlightsBodyTextColor, #000000);
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .Heading {
        top: 0rem;
        margin-top: 2rem;
        margin-bottom: 0rem;
        color: black;
    }
}
