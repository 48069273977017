:root {
    --sidePanelBackgroundColor: '----';
    --sidePanelHeadingTextColor: '----';
    --sidePanelSubtitleTextColor: '----';
    --sidePanelBodyTextColor: '----';
}

.StandingsHeading {
    text-align: left;
    color: var(--sidePanelHeadingTextColor, #000000);
}

.Table {
    text-align: left;
    color: var(--sidePanelBodyTextColor, #000000);
}

.TableHeading {
    color: var(--sidePanelBodyTextColor, #000000);
}

.TableBody {
    color: var(--sidePanelBodyTextColor, #000000);
}

.TableTeamColumn {
    width: 5rem;
}