:root {
    --navigationBackgroundColor: '----';
    --navigationTextPrimaryColor: '----';
    --navigationTextHoverColor: '----';
    --navigationTextActiveColor: '----';
    --subNavigationBackgroundColor: '----';
    --subavigationTextPrimaryColor: '----';
    --subavigationTextHoverColor: '----';
    --subavigationTextActiveColor: '----';
}

.Navigation {
    display: block !important;
    padding: 0 !important;
    /* background-color: #3e0b8f; */
    background-color: var(--navigationBackgroundColor, #3e0b8f);
}

.NavigationTopLayer {
    width: 100%;
    margin: 0 !important;
}

.MenuButton {
    position: absolute;
    top: 1rem;
    right: 0rem;
}

.NavigationMiddleLayerCollapse a:hover {
    color: var(--navigationTextHoverColor, #fcba03);
}

.NavigationMiddleLayerCollapseList {
    width: 100%;
    height: 3rem;
    justify-content: center;
}

.NavigationMiddleLayerItem {
    display: inline-block;
    color: var(--navigationTextPrimaryColor, white);
    font-weight: 800;
    line-height: 30px;
}

.NavigationMiddleLayerItem a:hover {
    color: var(--navigationTextHoverColor, #fcba03);
}

.NavigationMiddleLayerDropdownItem {
    display: inline-block;
    background-color: var(--subNavigationBackgroundColor, #2a0a54);
    height: 100%;
    color: var(--navigationTextPrimaryColor, white);
    font-weight: 800;
    line-height: 30px;
}

.NavigationBottomLayerCollapse a:hover {
    color: var(--subNavigationTextHoverColor, #fcba03);
}

.NavigationBottomLayerCollapseList {
    background-color: var(--subNavigationBackgroundColor, #2a0a54);
    width: 100%;
    height: 3rem;
    justify-content: center;
    line-height: 30px;
}

.NavigationBottomLayerItem {
    display: inline-block;
    color: var(--subNavigationTextPrimaryColor, white);
    font-weight: 800;
    line-height: 30px;
}

.NavigationInlineLayerCollapse {
    display: none;
}

.NavigationInlineLayerCollapseList {
    background-color: var(--subNavigationBackgroundColor, #2a0a54);
    width: 100%;
    height: 3rem;
    justify-content: center;
    line-height: 30px;
}

.NavigationInlineLayerItem {
    display: none;
    color: var(--subNavigationTextPrimaryColor, white);
    font-weight: 800;
    line-height: 30px;
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .NavigationMiddleLayerCollapseList {
        display: block !important;
        height: auto;
    }
    .NavigationMiddleLayerItem {
        display: block;
    }
    .NavigationBottomLayerCollapseList {
        display: none !important;
        height: auto;
    }
    .NavigationBottomLayerItem {
        display: none;
    }
    .NavigationInlineLayerCollapse {
        display: block;
    }
    .NavigationInlineLayerCollapseList {
        display: block !important;
        height: auto;
    }
    .NavigationInlineLayerItem {
        display: block;
    }
}
