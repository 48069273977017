:root {
    --alertsBackgroundColor: '----';
    --alertsHeadingTextColor: '----';
    --alertsSubtitleTextColor: '----';
    --alertsBodyTextColor: '----';
}

.PublishedAlertsBanner {
    display: block;
    text-align: center;
    padding: 1rem 10rem;
    background-color: 
}

.AlertBox {
    width: auto;
    border: 5px solid var(--alertsBackgroundColor, #a70000);
    text-align: left;
}

.AlertHeading {
    background-color: var(--alertsBackgroundColor, #a70000);;
    vertical-align: middle;
}

.AlertText {
    padding: 1rem 2rem;
}

.AlertTitle {
    color: var(--alertsHeadingTextColor, #000000)
}

.AlertSubtitle {
    padding-bottom: 1rem;
    color: var(--alertsSubtitleTextColor, #000000)
}

.AlertSummary {
    color: var(--alertsBodyTextColor, #000000)
}

.AlertIcon {
    color: white;
    margin: 1rem 2rem;
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .PublishedAlertsBanner {
        padding: 1rem 2rem;
    }
}
