:root {
    --bodyBackgroundColor: '----';
    --bodyTextPrimaryColor: '----';
    --bodyTextSecondaryColor: '----';
    --bodyTextLinkColor: '----';
    --bodyPrimaryColor: '----';
    --bodyAccentColor: '----';
    --bodyAlertBackgroundColor: '----';
    --bodyAlertTextColor: '----';
    --mainPanelBackgroundColor: '----';
    --mainPanelTextPrimaryColor: '----';
    --sidePanelBackgroundColor: '----';
    --sidePanelTextPrimaryColor: '----';
}

.Home {
    background-color: var(--bodyBackgroundColor, #ffffff);
    color: var(--bodyTextPrimaryColor, #000000);
}

@media screen and (min-width: 1200px) {
    .Home {
        padding: 0 2rem;
    }
}
