:root {
    --bodyPrimaryColor: '----';
    --mainPanelBackgroundColor: '----';
    --mainPanelHeadingTextColor: '----';
    --mainPanelSubtitleTextColor: '----';
    --mainPanelBodyTextColor: '----';
}

.SingleRowDatePicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DateHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: var(--mainPanelHeadingTextColor, #000000);
}

.Table {
    margin: 1rem 0;
    width: auto !important;
    text-align: center;
}

.TableHeading {
    color: var(--mainPanelHeadingTextColor, #000000);
}

.TableBody {
    color: var(--mainPanelBodyTextColor, #000000);
}

.TableCell {
    cursor: pointer;
}

.TableCellDate {
    display: block;
    width: 4rem;
    line-height: 4rem;
}

.SelectedDate {
    border: 2px solid #007bff;
    border-radius: 50%;
    background: #007bff;
    color: white;
}

.Today {
    border: 2px solid #dc3545;
    border-radius: 50%;
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 575.98px) {
    .SingleRowDatePicker {
        font-size: 0.5rem;
    }
    .TableCellDate {
        display: block;
        width: 1.5rem;
        line-height: 1.5rem;
    }
}