:root {
    --sidePanelBackgroundColor: '----';
    --sidePanelHeadingTextColor: '----';
    --sidePanelSubtitleTextColor: '----';
    --sidePanelBodyTextColor: '----';
}

.LocationsHeading {
    text-align: left;
    color: var(--sidePanelHeadingTextColor, #000000);
}

.Title {
    color: var(--sidePanelHeadingTextColor, #000000);
}

.Body {
    color: var(--sidePanelBodyTextColor, #000000);
}

.LocationsCard {
    background-color: transparent;
    text-align: left;
    color: var(--sidePanelBackgroundColor, #ffffff);
    padding: 2rem 0rem;
}