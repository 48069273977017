:root {
    --bodyPrimaryColor: '----';
    --bodyAccentColor: '----';
    --sidePanelBackgroundColor: '----';
    --sidePanelTextPrimaryColor: '----';
}

.SidePanel {
    background-color: var(--sidePanelBackgroundColor, #cccccc);
    color: var(--sidePanelTextPrimaryColor, #000000);
    padding: 2rem;
    margin: 0;
}