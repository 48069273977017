:root {
    --announcementsBackgroundColor: '----';
    --announcementsHeadingTextColor: '----';
    --announcementsSubtitleTextColor: '----';
    --announcementsBodyTextColor: '----';
}

.PublishedAnnouncementsCarousel img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
}

.CarouselIndicators {
    position: absolute;
    top: 0.5rem;
}

.AnnouncementText {
    width: 70%;
    position: absolute;
    top: 2rem;
    left: 15%;
    right: 10rem;
    color: white;
    text-align: left;
}

.AnnouncementTitle {
    color: var(--announcementsHeadingTextColor, #ffffff);
}

.AnnouncementSubtitle {
    padding-bottom: 2rem;
    color: var(--announcementsSubtitleTextColor, #ffffff);
}

.AnnouncementSummary {
    font-size: large;
    color: var(--announcementsBodyTextColor, #ffffff);
    text-shadow: 0rem 0rem 0.25rem black;
}
