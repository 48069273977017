:root {
    --mainPanelBackgroundColor: '----';
    --mainPanelHeadingTextColor: '----';
    --mainPanelSubtitleTextColor: '----';
    --mainPanelBodyTextColor: '----';
}

.MainPanelUpcomingGamesByLocation {
    background-color: var(--mainPanelBackgroundColor, #eeeeee);
    color: var(--mainPanelBodyTextColor, #000000);
    padding: 2rem;
}

.UpcomingGamesHeading {
    text-align: left;
    color: var(--mainPanelHeadingTextColor, #000000);
}

.LeagueSeasonHeading {
    text-align: left;
    color: var(--mainPanelHeadingTextColor, #000000);
}

.LocationHeading {
    padding-top: 0.25rem;
    text-align: left;
    color: var(--mainPanelHeadingTextColor, #000000);
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 575.98px) {
    .MainPanelUpcomingGamesByLocation {
        padding: 1rem;
    }
}