:root {
    --bodyPrimaryColor: '----';
    --mainPanelBackgroundColor: '----';
    --mainPanelHeadingTextColor: '----';
    --mainPanelSubtitleTextColor: '----';
    --mainPanelBodyTextColor: '----';
}

.TableHeading {
    background-color: var(--bodyPrimaryColor, #2a0a54);
    color: var(--mainPanelSubtitleTextColor, white);
}

.TableBody {
    color: var(--mainPanelBodyTextColor, white);
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 575.98px) {
    .Table {
        font-size: 1rem;
    }
}